.reset {
    width: 40%;
    background-color: #F2F2F2;
    text-align: center;
    padding: 10px;
    letter-spacing: .1rem;
    border: none;

}
.text2 {
    margin: 0;
    color: #2A2A2A;
    font-size: 20px;
    
}
@media screen and (max-width: 520px) {
    .text2 {
        font-size: 14px;
    }
    .reset {
        width: 40%;
    }
  }