.scramble {
    position: absolute;
    width: 500px;
    height: 500px;
    align-items: center;
    letter-spacing: .1rem;
    z-index: 20;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 8px 32px 0 rgb(31, 38, 135, 0.37);
    -webkit-backdrop-filter: blur(20px) invert(70%);
    backdrop-filter: blur(20px) invert(70%);
    justify-content: center;
}

  
.headingtext {
    color: #f2f2f2;
    margin: 0;
    font-size: 20px;
    font-weight: 350;
    display: flex;
}
.timetext {
    color: #f2f2f2;
    margin: 0;
    font-size: 60px;
    font-weight: 350;
    display: flex;
}
.besttimetext {
    color: #f2f2f2;
    margin: 0;
    font-size: 50px;
    font-weight: 350;
    display: flex;
}
.text3 {
    margin: 0;
    color: #f2f2f2;
    font-size: 180px;
    font-weight: 150;
    background: none; 
    border: none; 
    display: flex;
}
.text4 {
    margin: 0;
    color: #f2f2f2;
    font-size: 80px;
    font-weight: 150;
    background: none; 
    border: none; 
    display: flex;
}
@media screen and (max-width: 520px) {
    .scramble {
        width: 280px;
        height: 280px;
    }
    .headingtext {
        color: #f2f2f2;
        margin: 0;
        font-size: 10px;
        font-weight: 350;
    }
    .timetext {
        color: #f2f2f2;
        margin: 0;
        font-size: 30px;
        font-weight: 350;
        display: flex;
    }
    .besttimetext {
        color: #f2f2f2;
        margin: 0;
        font-size: 25px;
        font-weight: 350;
        display: flex;
    }
    .text3 {
        margin: 0;
        color: #f2f2f2;
        font-size: 60px;
        font-weight: 350;
        background: none; 
        border: none; 
        display: flex;
    }
    .text4 {
        margin: 0;
        color: #f2f2f2;
        font-size: 60px;
        font-weight: 150;
        background: none; 
        border: none; 
        display: flex;
    }
  }