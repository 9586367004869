.heading {
    margin-top: 100px;
    margin-bottom: 30px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
}
.logocontainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.logo {
    background-image:url(../assets/hueshiftlogo.svg);
    width: 100%;
    height: 50%;
    margin: auto;
    background-repeat:no-repeat;
    background-size: 100%;
}
.h1 {
    margin: auto;
    font-size: 36px;
    color: #ffffff;
    letter-spacing: .15rem;
}
.controlbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}

@media screen and (max-width: 520px) {
    .h1 {
        margin: auto;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: .1rem;
    }
    .heading {
        margin-top: 30px;
        height: 40px;
    }
    .logo {
        background-image:url(../assets/hueshiftlogo.svg);
        width: 100%;
        height: 40%;
        margin: auto;
        background-repeat:no-repeat;
        background-size: 80%;
    }
  }
