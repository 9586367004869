.edge {
    background-color: white;
    margin: 0;
}
.yellowedge {
    background-color: #00E562;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
    transition: background-color 0.2s ease-in 100ms, box-shadow 0.2s ease-in 100ms;
    margin: 0;
}
.blueedge {
    background-color: #0078FF;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
    margin: 0;
    transition: background-color 0.2s ease-in 100ms, box-shadow 0.2s ease-in 100ms;
}
.rededge {
    background-color: #FF4685;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
    transition: background-color 0.2s ease-in 100ms, box-shadow 0.2s ease-in 100ms;
    margin: 0;
}