.body {
  background-color: #2A2A2A;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.gamecontainer {
  width: 500px;
}
.foot {
  width: 500px;
  height: 200px;
}

.instructions {
  color: #f2f2f2;
  padding-top: 30px;
  text-align: left;
  font-size: 22px;
  font-weight: 100;
}
.madeby {
  color: #f2f2f2;
  padding-top: 30px;
  text-align: center;
  font-size: 22px;
  font-weight: 100;
}
.boldletters {
  font-weight: 400;
  color: #f2f2f2;
  text-decoration: underline;
  text-decoration-color: #f2f2f2;
}
@media screen and (max-width: 520px) {
  .instructions {
    font-size: 14px;
  }
  .gamecontainer {
    width: 280px;
  }
  .foot {
    width: 280px;
    height: 400px;
  }
  .madeby {
    font-size: 14px;
  }
}

