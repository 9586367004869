
.Timer {
    width: 17%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.timertext{
    text-align: center;
    margin: auto;
    font-size: 15px;
    font-weight: 200;
    color: #ffffff;
    letter-spacing: .1rem;
}
.time{
    text-align: center;
    margin: auto;
    font-size: 30px;
    font-weight: 200;
    color: #ffffff;
    letter-spacing: .05rem;
}
@media screen and (max-width: 520px) {
    .timertext{
        font-size: 10px;
    }
    .time{
        font-size: 20px;
    }
    .Timer {
        width: 20%;
    }
  }