.inner {
    z-index: 10;
}
.inner:after {
    content: '\A';
    position: absolute;
    top: 30%; bottom: 0;
    left: 30%; 
    width: 40%;
    height: 40%;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.586);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}
.red {
    background-color: #FF4685;
}
.yellow {
    background-color: #00E562;
}
.blue {
    background-color: #0078FF;
}
.up {
    width: 0%;
    height: 10%;
    left: 10%;
    position: absolute;
    animation: 0.25s ease growWidth 1;
}
.down {
    width: 0%;
    height: 10%;
    position: absolute;
    bottom: 0;
    right: 10%;
    animation: 0.35s ease growWidth 1;
}
.left {
    width: 10%;
    height: 0%;
    bottom: 10%;
    position: absolute;
    animation: 0.35s ease growHeight 1;
}
.right {
    width: 10%;
    height: 0%;
    top: 10%;
    right: 0;
    position: absolute;
    animation: 0.35s ease growHeight 1;
}

@keyframes growWidth {
    from {
        width: 0%;
    }
    to {
        width: 80%;
    }
}

@keyframes growHeight {
    from {
        height: 0%;
    }
    to {
        height: 80%;
    }
}





