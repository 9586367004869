.yellowtop2 {
    background-image: url("../assets/yellow.svg");
    position: absolute;
}
.redtop2 {
    background-image: url("../assets/red.svg");
    position: absolute;
}
.bluetop2 {
    background-image: url("../assets/blue.svg");
    position: absolute;
}
.yellowright2 {
    background-image: url("../assets/yellow.svg");
    position: absolute;
    transform: rotate(90deg);
}
.redright2 {
    background-image: url("../assets/red.svg");
    position: absolute;
    transform: rotate(90deg);
}
.blueright2 {
    background-image: url("../assets/blue.svg");
    position: absolute;
    transform: rotate(90deg);
}
.yellowleft2 {
    background-image: url("../assets/yellow.svg");
    position: absolute;
    transform: rotate(270deg);
}
.redleft2 {
    background-image: url("../assets/red.svg");
    position: absolute;
    transform: rotate(270deg);
    
}
.blueleft2 {
    background-image: url("../assets/blue.svg");
    position: absolute;
    transform: rotate(270deg);
}
.yellowbottom2 {
    background-image: url("../assets/yellow.svg");
    position: absolute;
    transform: rotate(180deg);
}
.redbottom2 {
    background-image: url("../assets/red.svg");
    position: absolute;
    transform: rotate(180deg);
}
.bluebottom2 {
    background-image: url("../assets/blue.svg");
    position: absolute;
    transform: rotate(180deg);
}