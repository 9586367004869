.button {
    width: 15%;
    border-style: solid;
    border-color: #F2F2F2;
    border-width: 4px;
    text-align: center;
    padding: 10px;
    background-color: #2A2A2A;

}
.text {
    margin: 0;
    color: #F2F2F2;
    font-size: 18px;
}
@media screen and (max-width: 520px) {
    .text {
        font-size: 12px;
    }
    .button {
        width: 15%;
        border-style: solid;
        border-color: #F2F2F2;
        border-width: 4px;
        text-align: center;
        padding: 0px;
    
    }
  }