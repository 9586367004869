.nub {
    background-color: #F2F2F2;
    z-index: 10;
    background: rgba(247, 247, 247, 1);
    box-shadow: 0 4px 30px rgba(39, 39, 39, 0.779);
}
.nubrow {
    display: flex;
    justify-content: space-between;
    
}